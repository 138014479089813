* {
  font-family: 'Rubik', monospace;
  box-sizing: border-box;
}

html, body, #root {
  background-color: #151922;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.MuiAutocomplete-listbox, .css-ghb1l5-MuiPaper-root {
  background: #353B49!important;
}